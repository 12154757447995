<script lang="ts" setup>
import { ref } from "vue";
import PermissionsGrid from "@/components/iam/PermissionsGrid.vue";

const addLink = ref({ name: "IAM-PermissionAdd", params: { permissionKey: "-", addModus: true } });
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <permissions-grid :addLink="addLink"></permissions-grid>
  </v-container>
</template>
